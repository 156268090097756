// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("images/background-header.jpeg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".background-image-1 {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-position: center; \n    background-repeat: no-repeat; \n    background-size: cover;\n}\n.row-container {\n    padding-left: 15%;\n    padding-right: 15%;\n}\nimg {\n    width: 100%;\n    height: auto;\n}\n\n@media only screen and (max-width: 425px) {\n    .row-container {\n        padding-left: 5%;\n        padding-right: 5%;\n    }\n}\n@media only screen and (max-width: 768px) {\n    .row-container {\n        padding-left: 5%;\n        padding-right: 5%;\n    }\n}\n@media only screen and (max-width: 1024px) {\n    .row-container {\n        padding-left: 5%;\n        padding-right: 5%;\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,yDAAsD;IACtD,2BAA2B;IAC3B,4BAA4B;IAC5B,sBAAsB;AAC1B;AACA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI;QACI,gBAAgB;QAChB,iBAAiB;IACrB;AACJ;AACA;IACI;QACI,gBAAgB;QAChB,iBAAiB;IACrB;AACJ;AACA;IACI;QACI,gBAAgB;QAChB,iBAAiB;IACrB;AACJ","sourcesContent":[".background-image-1 {\n    background-image: url(\"images/background-header.jpeg\");\n    background-position: center; \n    background-repeat: no-repeat; \n    background-size: cover;\n}\n.row-container {\n    padding-left: 15%;\n    padding-right: 15%;\n}\nimg {\n    width: 100%;\n    height: auto;\n}\n\n@media only screen and (max-width: 425px) {\n    .row-container {\n        padding-left: 5%;\n        padding-right: 5%;\n    }\n}\n@media only screen and (max-width: 768px) {\n    .row-container {\n        padding-left: 5%;\n        padding-right: 5%;\n    }\n}\n@media only screen and (max-width: 1024px) {\n    .row-container {\n        padding-left: 5%;\n        padding-right: 5%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
