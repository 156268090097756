import './App.css';

import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
import About from './pages/About/About';

import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate
} from "react-router-dom";

function App() {
    return (
		<Router>
			<div className="container-fluid">
				<Navbar />
				<Routes>
				  <Route path="/about" element={<About />} />
					<Route path="/" element={<Home />} />
					<Route path="*"element={<Navigate to="/" />} />
				</Routes>
				<Footer />
			</div>
		</Router>
    );
}
export default App;
