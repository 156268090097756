import './Footer.css';
import { Link } from "react-router-dom";

function Footer() {
    return (
		<>
			<div className="row row-container background-image-1 text-white">
				<div className="col-12 px-5">
					<div className="row">
						<div className="col-12 col-md-6 col-lg-4 py-3 text-center border-bottom-mobile">
							<div className="fw-bold">
								Expertise
							</div>
							<div className="fw-light pt-4">
								<div className="pb-2">
									<Link to="/" className="text-white text-decoration-none">Web development</Link>
								</div>
								<div className="pb-2">
									<Link to="/" className="text-white text-decoration-none">Web application maintenance</Link>
								</div>
								<div className="pb-2">
									<Link to="/" className="text-white text-decoration-none">IT consultancy</Link>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-4 py-3 text-center border-bottom-mobile">
							<div className="fw-bold">
								Contact
							</div>
							<div className="fw-light pt-4">
								<div className="pb-2">
									<div>
										<i className="fas fa-envelope pe-2"></i>
										<a href="mailto:office@it4best.com" className="text-white text-decoration-none">
											office@it4best.com
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className="col-12 col-lg-4 py-3 text-center">
							<div className="fw-bold">
								Follow us
							</div>
							<div className="fw-light pt-4">
								<a 
									className="text-white text-decoration-none fs-2 pe-2" 
									href="https://www.facebook.com/it4best"
									target="_blank"
									rel="noreferrer"
								>
									<i className="fab fa-facebook-square"></i>
								</a>
								
								<a 
									className="text-white text-decoration-none fs-2 pe-2" 
									href="https://twitter.com/it4_best"
									target="_blank"
									rel="noreferrer"
								>
									<i className="fab fa-twitter-square"></i>
								</a>
								<a 
									className="text-white text-decoration-none fs-2" 
									href="https://www.linkedin.com/company/it4best/about/"
									target="_blank"
									rel="noreferrer"
								>
									<i className="fab fa-linkedin"></i>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row row-container background-image-1 text-white py-5">
				<div className="col-12">
					<div className="text-center">
						<span className="fw-light">© {(new Date()).getFullYear()} Copyright:</span>
						<Link className="text-white text-decoration-none fw-bold" to="/https://it4best.com/">
							IT4Best.com
						</Link>
					</div>
				</div>
			</div>
		</>
    );
}

export default Footer;