import './Home.css';

function Section(props) {
    return (
        <div className={props.containerClass}>
            <div className="fs-1">
                <i className={props.iconClass}></i>
            </div>
            <div className="fs-5 fw-bold py-3">
                {props.header}
            </div>
            <div className="fw-light">
                {props.content}
            </div>
        </div>
    )
}

function Home() {

    const sectionData = [
        {
            iconClass: "fa-solid fa-light fa-hand text-info",
            header: "Fast & Customized",
            content: "We offer multiple components & designs, to choose from, in order to create a personalised web solution from scratch, in a quick and efficient manner. We also provide an admin pannel so you can modify the looks, or update the content, to keep your online presence fresh.",
            containerClass: "col-12 col-md-6 col-xxl-3 pb-5 pb-md-0"
        },
        {
            iconClass: "fa-solid fa-clipboard-list text-secondary" ,
            header: "Sophisticated & Thorough",
            content: "If our existing components are not enough to meet your needs, we provide a full technical support to create a solution, custom-tailored for you! From design to development, you can count on us to build you the very best online product!",
            containerClass: "col-12 col-md-6 col-xxl-3 pb-5 pb-md-0"
        },
        {
            iconClass: "fa-solid fa-screwdriver-wrench text-warning" ,
            header: "Maintenance & Beyond",
            content: "All projects start small & easy but somewhere along the line, it can become very hard to maintain and scale if it's not been done properly. If you have hit such a block, or simply are in search of a technical team that can help you maintain and extend the features of your product, you have found it!",
            containerClass: "col-12 col-md-6 col-xxl-3 pt-0 pt-md-5 pt-xxl-0 pb-5 pb-md-0"
        },
        {
            iconClass: "fa-solid fa-thumbs-up text-success" ,
            header: "Scalability & Architecture",
            content: "Quantity matters but not at the cost of Quality. We always keep in mind that your dream will grow bigger and bigger, so we always provide a solution that is scalable, maintainable and well architectured. We provide all the technical steps & decisions, taken along the way, to encourage transperancy and to keep your mind at rest that we move in the right direction!",
            containerClass: "col-12 col-md-6 col-xxl-3 pt-0 pt-md-5 pt-xxl-0",
        }
    ];
    function Sections() {
		const sectionDataMarkup = sectionData.map((item, index) => {
             return (
				<Section
				    key={index}
					iconClass={item.iconClass}
					header={item.header}
                    content={item.content}
                    containerClass={item.containerClass}
				/>
		    );
		});
		return sectionDataMarkup;
	}    

    return (
        <>
            <div className="row row-container background-image-1 text-white py-5">
                <div className="col-12 col-lg-8 pb-5">
                    <div>
                        <div className="fs-3">
                            Quality. Efficiency. Care.
                        </div>
                        <div className="big-text fw-bold lh-sm">
                            Realising your DREAMS ONLINE, should be EASY.  
                        </div>
                        <div className="fs-5 pt-4 pb-5">
                            Do you want to create an online profile OR a website for your business OR a web application for your product? We offer a variety of solutions, simple to complex, easy to sophisticated, to fit the needs of our customers!
                        </div>
                        <div>
                            <button className="bg-warning py-2 px-4 border-0 rounded">
                                <a href="mailto:office@it4best.com" className="text-decoration-none">
                                    <small className="fw-bold text-uppercase">
                                        contact us
                                    </small>
                                </a>
                            </button>
                        </div>
                        <div className="pt-3">
                            <small>Help us help you achieve the very best</small>
                        </div>
                    </div>
                </div>
                <div className="d-none d-lg-block col-4 pb-5"></div>
            </div>
            <div className="row row-container bg-light py-5">
                <div className="col-12 px-0 px-md-5">
                    <div className="row pb-5">
                        <div className="col-12">
                            <div>
                                <div className="fs-2 fw-bold py-3">
                                    You Dream & We Create
                                </div>
                                <div className="fw-light">
                                    <div>
                                        IT4Best takes away the complexity of development so you can focus on what matters the most:
                                        Your next Idea!
                                        <i className="fa-regular fa-lightbulb text-warning"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
	 	                <Sections />	 
                    </div>
                </div>
            </div>
        </>
    );
   
}

export default Home;