import './About.css';
import teamImage from './../../images/team.jpg';
import youMatterImage from './../../images/you_matter.jpg';

function About() {
    return (
        <>
            <div className="row row-container mt-5">
                <div className="col-12 col-md-3 mb-5 mb-md-0">
                    <img src={youMatterImage} alt="You" />
                </div>
                <div className="col-12 col-md-9 d-flex align-items-center">
                    <div>
                        <div className="fs-1">
                            <div className="fw-bold">
                                We are about you!
                            </div>      
                        </div>    
                        <div className="pe-3">
                            <div>
                                The mission of IT4Best is to provide an easy way of 
                                implementing online solutions! Even if you have zero
                                experience in creating web applications, we are your
                                technical team and ready to help you bring your ideas to life!
                            </div>
                            <div className="pt-4">
                                We provide solutions, custom tailored for your need,
                                so you stand out! If you wanted your own technical team
                                to start or maintain your online business without having
                                to hire a new agency or developer at every turn, your search
                                ends with us!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row row-container my-5">
                <div className="col-12 col-md-8 mt-5 mt-md-0 d-flex align-items-center">
                    <div>
                        <div className="fs-1">
                            <div className="fw-bold">
                                Our Story
                            </div>      
                        </div>    
                        <div className="pe-3">
                            <div>
                                Think about someone, wanting to have an online presense,
                                a portfolio, a website to market their ideas or advertise
                                their business, how could they do it? There are multiple
                                solutions available but none of them can be customized to
                                the point where it can become actually YOURS!
                            </div>
                            <div className="pt-4">
                                That was the need which inspired us to create a pathway for
                                such individuals of creative minds, so that they can build
                                something truly remarkable without having to worry about
                                the technical difficulties along the way. That is what gave birth
                                to it4best!
                            </div>
                            <div className="pt-4">
                                Your vision, our experience, together we bring your ideas to life. Help us help you! Your next dream is just one email away!
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 d-flex justify-content-center align-items-center mt-5 mt-md-0">
                    <img src={teamImage} alt="Team" />
                </div>
            </div>
            <div className="row row-container my-5">
                <div className="d-none d-md-block col-md-4"></div>
                <div className="col-12 col-md-4">
                    <button className="btn btn-primary py-2 px-4 border-0 rounded w-100">
                        <a href="mailto:office@it4best.com" className="text-decoration-none text-white">
                            <small className="fw-bold text-uppercase">
                                Let us help you!
                            </small>
                        </a>
                    </button>
                </div>
                <div className="d-none d-md-block col-md-4"></div>
            </div>
        </>
    );
}

export default About;
