import './Navbar.css';
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from 'react';
import logo from './../../images/logo.png';

function Hamburger(props) {
	if (props.showHamburgerProp) {
		return (
			<i id="hamburger-menu-icon" className="fa-solid fa-bars text-white"></i>
		);
	}
    return (
		<i id="cross-menu-icon" className="fa-solid fa-xmark text-white"></i>
	);
}

function Navbar() {
	const [bgBlue, setBgBlue] = useState('');
	const [showHamburger, setShowHamburger] = useState(true);
	const location = useLocation();

	useEffect(() => {
		setBgBlue(location.pathname)
	}, [location.pathname]);
	
    return (
        <div className="row row-container background-image-1 text-white">
			<div className="col-12 px-0 px-sm-5">
				<nav className="navbar navbar-expand-lg">
					<div className="container-fluid">
						<Link onClick={() => setBgBlue('/home')} className="navbar-brand text-white logo-width me-0 me-md-1" to="/">
							<img src={logo} alt="logo" />
						</Link>
						<Link onClick={() => setBgBlue('/home')} className="text-white text-decoration-none" to="/">
							IT4Best
						</Link>
					<button onClick={() => setShowHamburger(!showHamburger)} className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
						<Hamburger showHamburgerProp={showHamburger} />	
					</button>
					<div className="collapse navbar-collapse" id="navbarNav">
						<ul className="navbar-nav">
							<li className="nav-item">
							    <Link id="home-nav" onClick={() => setBgBlue('/home')} className={'nav-link text-white '+(bgBlue === '/home' || bgBlue === '/'?'active':'')} to="/">Home</Link>
							</li>
							<li className="nav-item">
							    <Link id="about-nav" onClick={() => setBgBlue('/about')} className={'nav-link text-white '+(bgBlue === '/about'?'active':'')} to="./about">About</Link>
							</li>
						</ul>
					</div>
					</div>
				</nav>
			</div>
		</div>   
    );
}

export default Navbar;